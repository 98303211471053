/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../templates/content-page";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Balintgruppen"), "\n", React.createElement(_components.p, null, "Die Beziehung zwischen Arzt bzw. Therapeut und Patient ist die wesentliche Grundlage jeglicher therapeutischen wie auch allgemein ärztlichen Arbeit."), "\n", React.createElement(_components.p, null, "Die von Michael Balint entwickelte Technik der Balintgruppen erlaubt es, im geschützten Rahmen der Gruppe assoziativ die verschiedensten Aspekte der jeweils vorgestellten Arzt-Patienten-Beziehung auszuloten. Die Reflexion in der Gruppe über das, was diese Beziehung im Einzelfall ausmacht, bereichert die Arbeit und hilft, besonders wenn es schwierig wird, neue Wege für die Begegnung mit dem Patienten zu finden."), "\n", React.createElement(_components.p, null, "Balintgruppen haben sich als so effektiv und positiv erwiesen, dass sie seit Jahren integraler Bestandteil der ärztlichen Weiterbildung in den Fächern Psychiatrie und Psychotherapie / Psychosomatik und Psychotherapie sowie Psychosomatische Grundversorgung und somit auch für Gynäkologie und Allgemeinmedizin sind. Aber auch über die Weiterbildungsordnung hinaus hat sich die Reflexion der therapeutischen Beziehung in der geleiteten Gruppe als vielfach nützlich und hilfreich erwiesen."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
